import React, { useLayoutEffect } from 'react';
import { HorizontalBarChart } from 'advance-components';
import Container from '../components/container';

export default function HorizontalBarChartScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Horizontal Bar Chart',
    });
  }, []);

  return (
    <Container>
      <HorizontalBarChart
        data={[
          { label: 'Amigotek', value: '₱ 2,550.00', percentage: 100 },
          { label: 'Harvester', value: '₱ 1,913.00', percentage: 75 },
          { label: 'Distillera Barako', value: '₱ 1,225.00', percentage: 50 },
        ]}
      />
    </Container>
  );
}
