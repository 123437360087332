import React, { useLayoutEffect } from 'react';
import { PieChart } from 'advance-components';
import Container from '../components/container';

export default function PieChartScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Pie Chart',
    });
  }, []);

  return (
    <Container>
      <PieChart
        data={[
          ['Title', 'Count'],
          ['A', 90],
          ['B', 45],
          ['C', 45],
          ['D', 45],
          ['E', 45],
          ['F', 45],
          ['G', 15],
          ['H', 15],
          ['I', 15],
        ]}
      />
    </Container>
  );
}
