import React, { useLayoutEffect } from 'react';
import { Platform, View, ScrollView } from 'react-native';
import { Input, Gap, Text, DividerLine } from 'advance-components';
import styled from 'styled-components/native';

const ResponsiveContainer = ({ children, style }) => {
  const Container = Platform.OS === 'web' ? View : ScrollView;

  return <Container style={style}>{children}</Container>;
};

const StyledCustomComponent = styled(ResponsiveContainer)`
  padding: 12px;
`;

export default function FileUploadScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'File Upload Input',
    });
  }, []);

  const onSelectFileSource = (source) => {
    // eslint-disable-next-line no-alert
    alert(source);
  };

  return (
    <StyledCustomComponent>
      <Text.Body weight='bold'>Single - Upload Version</Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload
        // eslint-disable-next-line no-alert
        onPress={() => alert('UPLOAD')}
        helperText='Helper text'
        onSelectFileSource={onSelectFileSource}
      />

      <Gap size='f48' height />

      <Text.Body weight='bold'>Single - Progress Version</Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload
        fileName='file.jpg'
        progress={50}
        helperText='Helper text'
        // eslint-disable-next-line no-alert
        onCancel={() => alert('CANCEL')}
      />

      <Gap size='f48' height />

      <Text.Body weight='bold'>Single - Valid Version</Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload
        fileName='file.jpg'
        helperText='Helper text'
        // eslint-disable-next-line no-alert
        onPreview={() => alert('PREVIEW')}
        // eslint-disable-next-line no-alert
        onDelete={() => alert('DELETE')}
      />

      <Gap size='f48' height />

      <Text.Body weight='bold'>Single - Error Version</Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload
        fileName='file.jpg'
        helperText='Helper text'
        // eslint-disable-next-line no-alert
        onReupload={() => alert('REUPLOAD')}
        // eslint-disable-next-line no-alert
        onDelete={() => alert('DELETE')}
        error
      />

      <Gap size='f48' height />

      <Text.Body weight='bold'>Single - Disabled Version</Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload helperText='Helper text' disabled />

      <Gap size='f48' height />
      <DividerLine />
      <Gap size='f48' height />

      <Text.Body weight='bold'>Multiple - Upload Version</Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload
        helperText='Helper text'
        multiple
        context={{
          uploadedFiles: [],
          removeUploadedFile: (index, defaultSortingNumber) => {
            // eslint-disable-next-line no-alert
            alert(
              `DELETE file index: ${index}, sorting number: ${defaultSortingNumber}`,
            );
          },
          cancelUploading: (index, number) => {
            // eslint-disable-next-line no-alert
            alert(`CANCEL upload file index: ${index}, number: ${number}`);
          },
          previewFile: (defaultSortingNumber) => {
            // eslint-disable-next-line no-alert
            alert(`PREVIEW sorting number: ${defaultSortingNumber}`);
          },
          downloadFile: (defaultSortingNumber) => {
            // eslint-disable-next-line no-alert
            alert(`DOWNLOAD sorting number: ${defaultSortingNumber}`);
          },
          onReuploadClick: (defaultSortingNumber) => {
            // eslint-disable-next-line no-alert
            alert(`REUPLOAD sorting number: ${defaultSortingNumber}`);
          },
          dragAndDropCard: {
            // eslint-disable-next-line no-alert
            onPress: () => alert('OPEN FILE SELECTOR'),
          },
        }}
        // eslint-disable-next-line no-alert
        helpFunction={() => alert('HELP')}
        onSelectFileSource={onSelectFileSource}
      />

      <Gap size='f48' height />

      <Text.Body weight='bold'>Multiple - Progress Version</Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload
        helperText='Helper text'
        multiple
        context={{
          uploadedFiles: [
            {
              status: 'uploaded',
              fileData: {
                error: '',
              },
              fileName: 'file1.docx',
              number: 1,
              defaultSortingNumber: 1,
            },
            {
              status: 'uploading',
              fileData: {
                error: '',
              },
              fileName: 'file2.jpg',
              progress: 50,
              number: 2,
              defaultSortingNumber: 2,
            },
          ],
          removeUploadedFile: (index, defaultSortingNumber) => {
            // eslint-disable-next-line no-alert
            alert(
              `DELETE file index: ${index}, sorting number: ${defaultSortingNumber}`,
            );
          },
          cancelUploading: (index, number) => {
            // eslint-disable-next-line no-alert
            alert(`CANCEL upload file index: ${index}, number: ${number}`);
          },
          previewFile: (defaultSortingNumber) => {
            // eslint-disable-next-line no-alert
            alert(`PREVIEW sorting number: ${defaultSortingNumber}`);
          },
          downloadFile: (defaultSortingNumber) => {
            // eslint-disable-next-line no-alert
            alert(`DOWNLOAD sorting number: ${defaultSortingNumber}`);
          },
          onReuploadClick: (defaultSortingNumber) => {
            // eslint-disable-next-line no-alert
            alert(`REUPLOAD sorting number: ${defaultSortingNumber}`);
          },
          dragAndDropCard: {
            // eslint-disable-next-line no-alert
            onPress: () => alert('OPEN FILE SELECTOR'),
          },
        }}
        // eslint-disable-next-line no-alert
        helpFunction={() => alert('HELP')}
        onSelectFileSource={onSelectFileSource}
      />

      <Gap size='f48' height />

      <Text.Body weight='bold'>Multiple - Valid Version</Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload
        helperText='Helper text'
        multiple
        context={{
          uploadedFiles: [
            {
              status: 'uploaded',
              fileData: {
                error: '',
              },
              fileName: 'file1.jpg',
              description: 'JPG file',
              number: 1,
              defaultSortingNumber: 1,
            },
            {
              status: 'uploaded',
              fileData: {
                error: '',
              },
              fileName: 'file2.csv',
              description: 'CSV file',
              number: 2,
              defaultSortingNumber: 2,
            },
          ],
          removeUploadedFile: (index, defaultSortingNumber) => {
            // eslint-disable-next-line no-alert
            alert(
              `DELETE file index: ${index}, sorting number: ${defaultSortingNumber}`,
            );
          },
          cancelUploading: (index, number) => {
            // eslint-disable-next-line no-alert
            alert(`CANCEL upload file index: ${index}, number: ${number}`);
          },
          previewFile: (defaultSortingNumber) => {
            // eslint-disable-next-line no-alert
            alert(`PREVIEW sorting number: ${defaultSortingNumber}`);
          },
          downloadFile: (defaultSortingNumber) => {
            // eslint-disable-next-line no-alert
            alert(`DOWNLOAD sorting number: ${defaultSortingNumber}`);
          },
          onReuploadClick: (defaultSortingNumber) => {
            // eslint-disable-next-line no-alert
            alert(`REUPLOAD sorting number: ${defaultSortingNumber}`);
          },
          dragAndDropCard: {
            // eslint-disable-next-line no-alert
            onPress: () => alert('OPEN FILE SELECTOR'),
            helperText: 'Helper text',
            errorMessage: 'Error message',
          },
        }}
        // eslint-disable-next-line no-alert
        helpFunction={() => alert('HELP')}
        onSelectFileSource={onSelectFileSource}
      />

      <Gap size='f48' height />

      <Text.Body weight='bold'>Multiple - Max Count Reached Version</Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload
        helperText='Helper text'
        multiple
        maxFileUploadCount={2}
        context={{
          uploadedFiles: [
            {
              status: 'uploaded',
              fileData: {
                error: '',
              },
              fileName: 'file1.jpg',
              description: 'JPG file',
              number: 1,
              defaultSortingNumber: 1,
            },
            {
              status: 'uploaded',
              fileData: {
                error: '',
              },
              fileName: 'file2.csv',
              description: 'CSV file',
              number: 2,
              defaultSortingNumber: 2,
            },
          ],
          removeUploadedFile: (index, defaultSortingNumber) => {
            // eslint-disable-next-line no-alert
            alert(
              `DELETE file index: ${index}, sorting number: ${defaultSortingNumber}`,
            );
          },
          cancelUploading: (index, number) => {
            // eslint-disable-next-line no-alert
            alert(`CANCEL upload file index: ${index}, number: ${number}`);
          },
          previewFile: (defaultSortingNumber) => {
            // eslint-disable-next-line no-alert
            alert(`PREVIEW sorting number: ${defaultSortingNumber}`);
          },
          downloadFile: (defaultSortingNumber) => {
            // eslint-disable-next-line no-alert
            alert(`DOWNLOAD sorting number: ${defaultSortingNumber}`);
          },
          onReuploadClick: (defaultSortingNumber) => {
            // eslint-disable-next-line no-alert
            alert(`REUPLOAD sorting number: ${defaultSortingNumber}`);
          },
          dragAndDropCard: {
            // eslint-disable-next-line no-alert
            onPress: () => alert('OPEN FILE SELECTOR'),
            helperText: 'Helper text',
            errorMessage: 'Error message',
          },
        }}
        // eslint-disable-next-line no-alert
        helpFunction={() => alert('HELP')}
        onSelectFileSource={onSelectFileSource}
      />

      <Gap size='f48' height />

      <Text.Body weight='bold'>
        Multiple - Drag and Drop Disabled Version
      </Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload
        helperText='Helper text'
        multiple
        maxFileUploadCount={2}
        context={{
          uploadedFiles: [
            {
              status: 'uploaded',
              fileData: {
                error: '',
              },
              fileName: 'file1.jpg',
              description: 'JPG file',
              number: 1,
              defaultSortingNumber: 1,
            },
            {
              status: 'uploaded',
              fileData: {
                error: '',
              },
              fileName: 'file2.csv',
              description: 'CSV file',
              number: 2,
              defaultSortingNumber: 2,
            },
          ],
          removeUploadedFile: (index, defaultSortingNumber) => {
            // eslint-disable-next-line no-alert
            alert(
              `DELETE file index: ${index}, sorting number: ${defaultSortingNumber}`,
            );
          },
          cancelUploading: (index, number) => {
            // eslint-disable-next-line no-alert
            alert(`CANCEL upload file index: ${index}, number: ${number}`);
          },
          previewFile: (defaultSortingNumber) => {
            // eslint-disable-next-line no-alert
            alert(`PREVIEW sorting number: ${defaultSortingNumber}`);
          },
          downloadFile: (defaultSortingNumber) => {
            // eslint-disable-next-line no-alert
            alert(`DOWNLOAD sorting number: ${defaultSortingNumber}`);
          },
          onReuploadClick: (defaultSortingNumber) => {
            // eslint-disable-next-line no-alert
            alert(`REUPLOAD sorting number: ${defaultSortingNumber}`);
          },
          dragAndDropCard: {
            // eslint-disable-next-line no-alert
            onPress: () => alert('OPEN FILE SELECTOR'),
            helperText: 'Helper text',
            errorMessage: 'Error message',
            disabled: true,
          },
        }}
        // eslint-disable-next-line no-alert
        helpFunction={() => alert('HELP')}
        onSelectFileSource={onSelectFileSource}
      />

      <Gap size='f48' height />

      <Text.Body weight='bold'>
        Multiple - Error Version (Below Max Count)
      </Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload
        helperText='Helper text'
        multiple
        context={{
          uploadedFiles: [
            {
              status: 'error',
              fileData: {
                error: 'errorMessage',
              },
              fileName: 'file1.jpg',
              number: 1,
              defaultSortingNumber: 1,
            },
            {
              status: 'error',
              fileData: {
                error: 'errorMessage',
              },
              fileName: 'file2.jpg',
              number: 2,
              defaultSortingNumber: 2,
            },
            {
              status: 'uploaded',
              fileData: {
                error: '',
              },
              fileName: 'file3.jpg',
              number: 3,
              defaultSortingNumber: 3,
              description: 'JPG file',
            },
            {
              status: 'uploaded',
              fileData: {
                error: '',
              },
              fileName: 'file4.csv',
              number: 4,
              defaultSortingNumber: 4,
              description: 'CSV file',
            },
            {
              status: 'uploading',
              fileData: {
                error: '',
              },
              fileName: 'file5.csv',
              number: 5,
              defaultSortingNumber: 5,
              progress: 50,
            },
          ],
          removeUploadedFile: (index, defaultSortingNumber) => {
            // eslint-disable-next-line no-alert
            alert(
              `DELETE file index: ${index}, sorting number: ${defaultSortingNumber}`,
            );
          },
          cancelUploading: (index, number) => {
            // eslint-disable-next-line no-alert
            alert(`CANCEL upload file index: ${index}, number: ${number}`);
          },
          previewFile: (defaultSortingNumber) => {
            // eslint-disable-next-line no-alert
            alert(`PREVIEW sorting number: ${defaultSortingNumber}`);
          },
          downloadFile: (defaultSortingNumber) => {
            // eslint-disable-next-line no-alert
            alert(`DOWNLOAD sorting number: ${defaultSortingNumber}`);
          },
          onReuploadClick: (defaultSortingNumber) => {
            // eslint-disable-next-line no-alert
            alert(`REUPLOAD sorting number: ${defaultSortingNumber}`);
          },
          dragAndDropCard: {
            // eslint-disable-next-line no-alert
            onPress: () => alert('OPEN FILE SELECTOR'),
            helperText: 'Helper text',
            errorMessage: 'Error message',
          },
        }}
        // eslint-disable-next-line no-alert
        helpFunction={() => alert('HELP')}
        onSelectFileSource={onSelectFileSource}
      />

      <Gap size='f48' height />

      <Text.Body weight='bold'>
        Multiple - Error Version (Max Count Reached)
      </Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload
        helperText='Helper text'
        multiple
        maxFileUploadCount={3}
        context={{
          uploadedFiles: [
            {
              status: 'error',
              fileData: {
                error: 'errorMessage',
              },
              fileName: 'file1.jpg',
              number: 1,
              defaultSortingNumber: 1,
            },
            {
              status: 'error',
              fileData: {
                error: 'errorMessage',
              },
              fileName: 'file2.jpg',
              number: 2,
              defaultSortingNumber: 2,
            },
            {
              status: 'uploaded',
              fileData: {
                error: '',
              },
              fileName: 'file3.jpg',
              number: 3,
              defaultSortingNumber: 3,
              description: 'JPG file',
            },
            {
              status: 'uploaded',
              fileData: {
                error: '',
              },
              fileName: 'file4.csv',
              number: 4,
              defaultSortingNumber: 4,
              description: 'CSV file',
            },
            {
              status: 'uploading',
              fileData: {
                error: '',
              },
              fileName: 'file5.csv',
              number: 5,
              defaultSortingNumber: 5,
              progress: 50,
            },
          ],
          removeUploadedFile: (index, defaultSortingNumber) => {
            // eslint-disable-next-line no-alert
            alert(
              `DELETE file index: ${index}, sorting number: ${defaultSortingNumber}`,
            );
          },
          cancelUploading: (index, number) => {
            // eslint-disable-next-line no-alert
            alert(`CANCEL upload file index: ${index}, number: ${number}`);
          },
          previewFile: (defaultSortingNumber) => {
            // eslint-disable-next-line no-alert
            alert(`PREVIEW sorting number: ${defaultSortingNumber}`);
          },
          downloadFile: (defaultSortingNumber) => {
            // eslint-disable-next-line no-alert
            alert(`DOWNLOAD sorting number: ${defaultSortingNumber}`);
          },
          onReuploadClick: (defaultSortingNumber) => {
            // eslint-disable-next-line no-alert
            alert(`REUPLOAD sorting number: ${defaultSortingNumber}`);
          },
          dragAndDropCard: {
            // eslint-disable-next-line no-alert
            onPress: () => alert('OPEN FILE SELECTOR'),
            helperText: 'Helper text',
            errorMessage: 'Error message',
          },
        }}
        // eslint-disable-next-line no-alert
        helpFunction={() => alert('HELP')}
        onSelectFileSource={onSelectFileSource}
      />

      <Gap size='f48' height />

      <Text.Body weight='bold'>Multiple - Disabled Version</Text.Body>
      <Gap size='f12' height />
      <Input.DocumentUpload helperText='Helper text' multiple disabled />
    </StyledCustomComponent>
  );
}
