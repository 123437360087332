import React, { useLayoutEffect } from 'react';
import { Spacer, Icon, AdvanceDetailsHeader } from 'advance-components';

export default function AdvanceDetailsHeaderScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Advance Details Header',
    });
  }, []);

  return (
    <>
      <AdvanceDetailsHeader
        simple
        iconName='Medicine'
        iconFamily={Icon.AdvanceLineIcon}
        disbursementDate='Nov 25, 2016'
        referenceNumber='32144DA'
        variant='dark'
      >
        Dark Simple Mobile Header
      </AdvanceDetailsHeader>
      <Spacer size='f24' height />
      <AdvanceDetailsHeader
        simple
        iconName='Medicine'
        iconFamily={Icon.AdvanceLineIcon}
        disbursementDate='Nov 25, 2016'
        referenceNumber='32144DA'
        variant='light'
      >
        Light Simple Mobile Header
      </AdvanceDetailsHeader>
    </>
  );
}
