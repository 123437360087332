import React, { useLayoutEffect } from 'react';
import { Spacer, DividerLine, PageEnd } from 'advance-components';
import Container from '../components/container';

export default function IllustrationScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Illustrations',
    });
  }, []);

  return (
    <Container>
      <PageEnd
        title='Any questions or feedback? Contact us at'
        label='support@advance.ph'
        width={217}
      />
      <DividerLine />
      <Spacer size='f24' height />
      <PageEnd type='empty' title='No more messages' />
    </Container>
  );
}
