import React, { useLayoutEffect, useState } from 'react';
import moment from 'moment';
import { Spacer, DatePicker } from 'advance-components';
import Container from '../components/container';

export default function DatePickerScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Date Picker',
    });
  }, []);

  const [futureDate, setFutureDate] = useState();
  const [pastDate, setPastDate] = useState();
  const [disabledDropDate, setDisabledDropDate] = useState();
  const [selectedDateWithState, setSelectedDateWithState] = useState();
  const defaultSelectedDate = moment().toDate();
  const getNearestPreviousWeekday = () => {
    let today = moment();

    if (today.day() === 0) {
      return today.subtract(2, 'days');
    } else if (today.day() === 6) {
      return today.subtract(1, 'days');
    } else {
      return today;
    }
  };
  const [error, setError] = useState(false);

  const validateAndUpdate = (selectedDate) => {
    setSelectedDateWithState(moment(selectedDate.dateString));
    setError(
      moment(selectedDate.dateString).day() === 0 ||
        moment(selectedDate.dateString).day() === 6,
    );
  };

  return (
    <Container>
      <DatePicker
        label='Date Picker'
        placeholder='Select Date'
        helper='Future dates (5 years)'
        minimumDate={moment().toDate()}
        maximumDate={moment().add(5, 'years').toDate()}
        defaultSelectedDate={defaultSelectedDate}
        value={futureDate}
        onSubmit={(selectedDate) => setFutureDate(selectedDate)}
      />
      <Spacer size='f12' height />
      <DatePicker
        label='Date Picker with disabled weekend'
        placeholder='Select Date'
        helper='Past dates (100 years)'
        maximumDate={moment().toDate()}
        minimumDate={moment().subtract(100, 'years').toDate()}
        value={pastDate}
        onSubmit={(selectedDate) => setPastDate(selectedDate)}
        defaultSelectedDate={getNearestPreviousWeekday()}
        disabledWeekend
        disabledSetButton
      />
      <DatePicker
        label='Date Picker with disabled weekend'
        placeholder='Select Date'
        helper='Without default date validation'
        maximumDate={moment().toDate()}
        minimumDate={moment().subtract(100, 'years').toDate()}
        value={pastDate}
        onSubmit={(selectedDate) => setPastDate(selectedDate)}
        disabledWeekend
        disabledSetButton
      />
      <Spacer size='f12' height />
      <DatePicker
        label='Date Picker with disabled future month and year'
        placeholder='Select Date'
        helper='Past dates (100 years)'
        maximumDate={moment().toDate()}
        minimumDate={moment().subtract(100, 'years').toDate()}
        disableDropOptions
        value={disabledDropDate}
        onSubmit={(selectedDate) => setDisabledDropDate(selectedDate)}
      />
      <Spacer size='f12' height />
      <DatePicker
        label='Date Picker disabled state'
        placeholder='Select Date'
        value={null}
        disabled
        helper='Disabled date picker helper text'
      />
      <Spacer size='f12' height />
      <DatePicker
        label='Date Picker with states'
        placeholder='Select Date'
        helper='weekday = success, weekend = error'
        maximumDate={moment().toDate()}
        minimumDate={moment().subtract(100, 'years').toDate()}
        error={error}
        success={!error}
        value={selectedDateWithState}
        onChange={(selectedDate) => validateAndUpdate(selectedDate)}
        defaultSelectedDate={defaultSelectedDate}
      />
      <Spacer size='f12' height />
      <DatePicker
        label='Date Range Picker'
        placeholder='Select start and end date'
        helper='Past dates (100 years)'
        maximumDate={moment().toDate()}
        minimumDate={moment().subtract(100, 'years').toDate()}
        rangePicker
      />
    </Container>
  );
}
