import React, { useLayoutEffect } from 'react';
import { Spacer, NavigationMenu, Colors, Text } from 'advance-components';
import Container from '../components/container';

export default function NavigationMenuScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Navigation Menu',
    });
  }, []);

  return (
    <Container backgroundColor={Colors.black}>
      <NavigationMenu
        fullName='Mike Yap'
        theme='dark'
        mainMenuItems={[
          { label: 'Home' },
          { label: 'Request Advance' },
          { label: 'Active Advances' },
          { label: 'Payments' },
        ]}
        dropdownItems={[
          { label: 'Personal Information', icon: 'person' },
          {
            label: 'Account',
            icon: 'settings',
            subItems: [
              { label: 'Uploaded Documents' },
              { label: 'Employment Details' },
              { label: 'Bank Account Details' },
              { label: 'Login and Security' },
            ],
          },
          {
            label: 'Refer Friends',
            icon: 'share',
            subItems: [
              { label: 'Sub Item 1' },
              {
                label: 'Sub Item 2',
                subItems: [
                  {
                    label: 'Sub Sub Item 1',
                    subItems: [
                      { label: 'Sub Sub Sub Item 1' },
                      { label: 'Sub Sub Sub Item 2' },
                    ],
                  },
                  { label: 'Sub Sub Item 2' },
                ],
              },
            ],
          },
          { label: 'Vouchers', icon: 'confirmation-number' },
          { label: 'Sign Out', icon: 'logout' },
        ]}
        dropdownHelpMenuItems={[
          { label: 'Contact us', onPress: () => {} },
          { label: 'Product feedback', onPress: () => {} },
        ]}
      />
      <Spacer size='f24' height />
      <Spacer size='f24' height />
      <Spacer size='f24' height />
      <Spacer size='f24' height />
      <Spacer size='f24' height />
      <Spacer size='f24' height />
      <Spacer size='f24' height />
      <Spacer size='f24' height />
      <Spacer size='f24' height />
      <NavigationMenu
        theme='light'
        fullName='Mike Yap'
        mainMenuItems={[
          { label: 'Home' },
          { label: 'Request Advance' },
          { label: 'Active Advances' },
          { label: 'Payments' },
        ]}
        dropdownItems={[
          { label: 'Personal Information', icon: 'person' },
          {
            label: 'Account',
            icon: 'settings',
            subItems: [
              { label: 'Uploaded Documents' },
              { label: 'Employment Details' },
              { label: 'Bank Account Details' },
              { label: 'Login and Security' },
            ],
          },
          {
            label: 'Refer Friends',
            icon: 'share',
            subItems: [
              { label: 'Sub Item 1' },
              {
                label: 'Sub Item 2',
                subItems: [
                  {
                    label: 'Sub Sub Item 1',
                    subItems: [
                      { label: 'Sub Sub Sub Item 1' },
                      { label: 'Sub Sub Sub Item 2' },
                    ],
                  },
                  { label: 'Sub Sub Item 2' },
                ],
              },
            ],
          },
          { label: 'Vouchers', icon: 'confirmation-number' },
          { label: 'Sign Out', icon: 'logout' },
        ]}
        dropdownHelpMenuItems={[
          { label: 'Contact us', onPress: () => {} },
          { label: 'Product feedback', onPress: () => {} },
        ]}
      />
      <Spacer size='f24' height />
      <Spacer size='f24' height />
      <Spacer size='f24' height />
      <Spacer size='f24' height />
      <Spacer size='f24' height />
      <Spacer size='f24' height />
      <Spacer size='f24' height />
      <Spacer size='f24' height />
      <Spacer size='f24' height />
      <NavigationMenu.Header
        theme='light'
        renderLeft={() => (
          <Text.BodyBase color={Colors.purple400}>Back to Home</Text.BodyBase>
        )}
        renderCenter={() => <Text.TitleBase>Advance</Text.TitleBase>}
        renderRight={() => <Text.TitleBase>Content Right</Text.TitleBase>}
      />
      <Spacer size='f24' height />
      <Spacer size='f24' height />
      <Spacer size='f24' height />
      <Spacer size='f24' height />
      <Spacer size='f24' height />
      <Spacer size='f24' height />
      <Spacer size='f24' height />
      <Spacer size='f24' height />
      <Spacer size='f24' height />
      <NavigationMenu.Header
        renderLeft={() => (
          <Text.BodyBase color={Colors.white}>Back to Home</Text.BodyBase>
        )}
        renderCenter={() => (
          <Text.TitleBase color={Colors.white}>Advance</Text.TitleBase>
        )}
        renderRight={() => (
          <Text.TitleBase color={Colors.white}>Content Right</Text.TitleBase>
        )}
      />
    </Container>
  );
}
