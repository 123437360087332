import React, { useLayoutEffect } from 'react';
import { Colors, SystemBar } from 'advance-components';

export default function SystemBarDarkScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'System Bar Dark',
      headerTintColor: Colors.white,
      headerStyle: {
        backgroundColor: Colors.primary40,
      },
      headerLeftContainerStyle: {
        paddingLeft: 24,
      },
      headerRightContainerStyle: {
        paddingRight: 24,
      },
      headerBackTitle: null,
    });
  }, []);

  return <SystemBar theme='dark' />;
}
