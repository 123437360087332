import React, { useLayoutEffect } from 'react';
import { Spacer, Payment } from 'advance-components';
import Container from '../components/container';

export default function PaymentScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Payment',
    });
  }, []);

  return (
    <Container>
      <Payment
        icon='others-stroke'
        purpose='Travel'
        paymentNo={1}
        paymentCount={2}
        amount={1000}
      />
      <Spacer size='f16' height />
      <Payment
        icon='others-stroke'
        purpose='Travel'
        paymentNo={1}
        paymentCount={2}
        amount={1000}
        dueDate={new Date('2024-01-01')}
        carryOver
      />
      <Spacer size='f16' height />
      <Payment.Grouped
        amount={15000}
        dueDate={new Date('2024-01-01')}
        carryOverCount={1}
        payments={[
          {
            icon: 'others-stroke',
            purpose: 'Travel',
            paymentNo: 1,
            paymentCount: 2,
            amount: 1000,
            dueDate: new Date('2024-01-01'),
            carryOver: true,
          },
          {
            icon: 'others-stroke',
            purpose: 'Travel',
            paymentNo: 1,
            paymentCount: 2,
            amount: 1000,
          },
          {
            icon: 'others-stroke',
            purpose: 'Travel',
            paymentNo: 1,
            paymentCount: 2,
            amount: 1000,
          },
        ]}
        uniqueLoansCount={3}
      />
      <Spacer size='f16' height />
      <Payment.Grouped
        amount={15000}
        dueDate={new Date('2024-01-01')}
        confirming
        payments={[
          {
            icon: 'others-stroke',
            purpose: 'Travel',
            paymentNo: 1,
            paymentCount: 2,
            amount: 1000,
          },
          {
            icon: 'others-stroke',
            purpose: 'Travel',
            paymentNo: 1,
            paymentCount: 2,
            amount: 1000,
          },
          {
            icon: 'others-stroke',
            purpose: 'Travel',
            paymentNo: 1,
            paymentCount: 2,
            amount: 1000,
          },
        ]}
        uniqueLoansCount={3}
      />
      <Spacer size='f16' height />
      <Payment.Grouped
        amount={15000}
        dueDate={new Date('2024-01-01')}
        paid
        payments={[
          {
            icon: 'others-stroke',
            purpose: 'Travel',
            paymentNo: 1,
            paymentCount: 2,
            amount: 1000,
          },
          {
            icon: 'others-stroke',
            purpose: 'Travel',
            paymentNo: 1,
            paymentCount: 2,
            amount: 1000,
          },
          {
            icon: 'others-stroke',
            purpose: 'Travel',
            paymentNo: 1,
            paymentCount: 2,
            amount: 1000,
          },
        ]}
        uniqueLoansCount={3}
      />
      <Spacer size='f16' height />
      <Payment.Grouped
        amount={15000}
        dueDate={new Date('2024-01-01')}
        payments={[
          {
            icon: 'others-stroke',
            purpose: 'Travel',
            paymentNo: 1,
            paymentCount: 2,
            amount: 1000,
          },
          {
            icon: 'others-stroke',
            purpose: 'Travel',
            paymentNo: 1,
            paymentCount: 2,
            amount: 1000,
          },
          {
            icon: 'others-stroke',
            purpose: 'Travel',
            paymentNo: 1,
            paymentCount: 2,
            amount: 1000,
          },
        ]}
        uniqueLoansCount={3}
      />
    </Container>
  );
}
