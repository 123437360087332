import React, { useLayoutEffect, useState } from 'react';
import { Input, Modal, Spacer, Text } from 'advance-components';
import Container from '../components/container';

export default function ModalScreen({ navigation }) {
  const [inputModal, setInputModal] = useState(false);
  const [promptModal, setPromptModal] = useState(false);
  const [instructionsModal, setInstructionsModal] = useState(false);
  const [informationModal, setInformationModal] = useState(false);

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Modal',
    });
  }, []);

  return (
    <Container>
      <Modal.Input
        active={inputModal}
        activeSetter={setInputModal}
        title='Modal Header'
        buttonName='Test'
      >
        <Input.TextField
          label='Input field'
          placeholder='Sample Content Here'
          helper='Helper Text'
          righticon='correct-solid'
        />
      </Modal.Input>
      <Text.TitleBase onPress={() => setInputModal(true)}>
        Input Modal
      </Text.TitleBase>
      <Spacer size='f24' height />
      <Modal.Prompt
        title='Modal Header'
        subtext='Optional subtext'
        description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque cursus ornare turpis, in consectetur velit convallis sed. Sed in nibh sit amet sem sodales consequat. In consectetur elit turpis, a faucibus nibh porta non.'
        buttons={[
          { name: 'Label', onPress: () => {}, icon: 'account-stroke' },
          { name: 'Label', onPress: () => {}, icon: 'account-stroke' },
        ]}
        active={promptModal}
        activeSetter={setPromptModal}
      />
      <Text.TitleBase onPress={() => setPromptModal(true)}>
        Prompt Modal
      </Text.TitleBase>
      <Spacer size='f24' height />
      <Modal.Instructions
        title='Header Title'
        subheader='Optional sub header content'
        label='Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        list={['Sample bullet point 1', 'Sample bullet point 2']}
        buttonName='Secondary'
        active={instructionsModal}
        activeSetter={setInstructionsModal}
      />
      <Text.TitleBase onPress={() => setInstructionsModal(true)}>
        Instructions Modal
      </Text.TitleBase>
      <Spacer size='f24' height />
      <Modal.Information
        title='Header Title'
        active={informationModal}
        activeSetter={setInformationModal}
        subheader='Optional sub header content'
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
        cursus ornare turpis, in consectetur velit convallis sed. Sed in nibh
        sit amet sem sodales consequat. In consectetur elit turpis, a faucibus
        nibh porta non.
      </Modal.Information>
      <Text.TitleBase onPress={() => setInformationModal(true)}>
        Information Modal
      </Text.TitleBase>
    </Container>
  );
}
