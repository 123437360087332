import React, { useLayoutEffect, useState } from 'react';
import { Input, Gap, Text, Colors, DividerLine } from 'advance-components';
import Container from '../components/container';
import Row from '../components/row';

const dropDownItems = Array.from({ length: 10 }, (_, index) => index + 1);

export default function InputScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Input',
    });
  }, []);

  const [pin, setPin] = useState('');
  const [text, setText] = useState({
    default: '',
    success: '',
    error: '',
  });
  const [text2, setText2] = useState({
    default: '',
    success: '',
    error: '',
  });
  const [text3, setText3] = useState({
    default: '',
    success: '',
    error: '',
  });
  const [dropDownValue, setDropDownValue] = useState(null);
  const [error, setError] = useState(false);
  const styles = {
    container: {
      padding: 40,
    },
    icon: {
      default: {
        margin: -2,
        cursor: 'default',
      },
      disabled: {
        color: Colors.neutral70,
        pointerEvents: 'none',
      },
    },
    fieldContainer: {
      backgroundColor: Colors.neutral100,
      maxWidth: 600,
      borderRadius: Gap.sizes.f8,
    },
  };

  return (
    <Container style={styles.container}>
      <Text.TitleBase>Text field</Text.TitleBase>
      <Gap size='f48' height />

      <Container style={styles.fieldContainer}>
        <Input.TextField
          label='Field label'
          helper='Helper text'
          defaultValue={text.default}
          value={text.default}
          onChangeText={(value) =>
            setText({
              default: value,
              success: text.success,
              error: text.error,
            })
          }
          placeholder='Sample content here'
        />

        <Gap size='f24' height />

        <Input.TextField
          label='Field label'
          helper='Helper text'
          defaultValue={text.error}
          value={text.error}
          onChangeText={(value) =>
            setText({
              default: text.default,
              success: text.success,
              error: value,
            })
          }
          placeholder='Sample content here'
          error
        />

        <Gap size='f24' height />

        <Input.TextField
          label='Field label'
          helper='Helper text'
          defaultValue={text.success}
          value={text.success}
          onChangeText={(value) =>
            setText({
              default: text.default,
              success: value,
              error: text.error,
            })
          }
          placeholder='Sample content here'
          success
        />

        <Gap size='f24' height />

        <Input.TextField
          label='Field label'
          helper='Helper text'
          placeholder='Sample content here'
          disabled
        />

        <Gap size='f48' height />
        <DividerLine />
        <Gap size='f48' height />

        <Input.TextField
          label='Field label'
          helper='Helper text'
          righticon='correct-solid'
          defaultValue={text2.default}
          value={text2.default}
          onChangeText={(value) =>
            setText2({
              default: value,
              success: text2.success,
              error: text2.error,
            })
          }
          placeholder='Sample content here'
        />

        <Gap size='f24' height />

        <Input.TextField
          label='Field label'
          helper='Helper text'
          righticon='correct-solid'
          defaultValue={text2.error}
          value={text2.error}
          onChangeText={(value) =>
            setText2({
              default: text2.default,
              success: text2.success,
              error: value,
            })
          }
          placeholder='Sample content here'
          error
        />

        <Gap size='f24' height />

        <Input.TextField
          label='Field label'
          helper='Helper text'
          righticon='correct-solid'
          defaultValue={text2.success}
          value={text2.success}
          onChangeText={(value) =>
            setText2({
              default: text2.default,
              success: value,
              error: text2.error,
            })
          }
          placeholder='Sample content here'
          success
        />

        <Gap size='f24' height />

        <Input.TextField
          label='Field label'
          helper='Helper text'
          righticon='correct-solid'
          placeholder='Sample content here'
          disabled
        />

        <Gap size='f48' height />
        <DividerLine />
        <Gap size='f48' height />

        <Input.TextField
          label='Field label'
          helper='Helper text'
          lefticon='account-stroke'
          righticon='correct-solid'
          defaultValue={text3.default}
          value={text3.default}
          onChangeText={(value) =>
            setText3({
              default: value,
              success: text3.success,
              error: text3.error,
            })
          }
          placeholder='Sample content here'
        />

        <Gap size='f24' height />

        <Input.TextField
          label='Field label'
          helper='Helper text'
          lefticon='account-stroke'
          righticon='correct-solid'
          defaultValue={text3.error}
          value={text3.error}
          onChangeText={(value) =>
            setText3({
              default: text3.default,
              success: text3.success,
              error: value,
            })
          }
          placeholder='Sample content here'
          error
        />

        <Gap size='f24' height />

        <Input.TextField
          label='Field label'
          helper='Helper text'
          lefticon='account-stroke'
          righticon='correct-solid'
          defaultValue={text3.success}
          value={text3.success}
          onChangeText={(value) =>
            setText3({
              default: text3.default,
              success: value,
              error: text3.error,
            })
          }
          placeholder='Sample content here'
          success
        />

        <Gap size='f24' height />

        <Input.TextField
          label='Field label'
          helper='Helper text'
          lefticon='account-stroke'
          righticon='correct-solid'
          placeholder='Sample content here'
          disabled
        />
      </Container>

      <Gap size='f96' height />
      <Text.TitleBase>Dropdown</Text.TitleBase>
      <Gap size='f48' height />

      <Input.DropDown
        label='Error and success state'
        onChange={(item) => setDropDownValue(item.value)}
        items={[
          { label: 'Default value', value: 'default', support: 'Support' },
          { label: 'Error value', value: 'error', support: 'Support' },
          { label: 'Success value', value: 'success', support: 'Support' },
        ]}
        placeholder='Selection from list'
        helper='Choose an option to change state'
        iconName='account-stroke'
        error={dropDownValue === 'error'}
        success={dropDownValue === 'success'}
      />

      <Gap size='f24' height />
      <Input.DropDown
        label='Field Label'
        items={[
          {
            label: 'Sole proprietorship',
            value: 'Sole proprietorship',
            support: 'Single owner',
          },
          { label: 'Partnership', value: 'Partnership', support: '2 owners' },
          { label: 'Corporation', value: 'Corporation', support: 'Complex' },
        ]}
        placeholder='Select business type'
        helper='You can select more than one'
      />
      <Gap size='f24' height />
      <Input.DropDown
        label='Long text for option'
        items={dropDownItems.map((number) => {
          const item = `Dropdown Item ${number}` + ' text'.repeat(number);

          return { label: item, value: item, support: 'support' + item };
        })}
        placeholder='Selection from list'
        helper='Helper text'
      />
      <Gap size='f24' height />
      <Input.DropDown
        label='Four Options'
        items={[
          {
            label: 'First option label',
            value: 'First option value',
            support: 'First option support',
          },
          {
            label: 'Second option label',
            value: 'Second option value',
            support: 'Second option support',
          },
          {
            label: 'Third option label',
            value: 'Third option value',
            support: 'Third option support',
          },
          {
            label: 'Fourth option label',
            value: 'Fourth option value',
            support: 'Fourth option support',
          },
        ]}
        placeholder='Select an option'
        helper='You can select an option'
      />
      <Gap size='f24' height />
      <Input.DropDown
        label='Five Options'
        items={[
          {
            label: 'First option label',
            value: 'First option value',
            support: 'First option support',
          },
          {
            label: 'Second option label',
            value: 'Second option value',
            support: 'Second option support',
          },
          {
            label: 'Third option label',
            value: 'Third option value',
            support: 'Third option support',
          },
          {
            label: 'Fourth option label',
            value: 'Fourth option value',
            support: 'Fourth option support',
          },
          {
            label: 'Fifth option label',
            value: 'Fifth option value',
            support: 'Fifth option support',
          },
        ]}
        placeholder='Select an option'
        helper='You can select an option'
      />
      <Gap size='f24' height />
      <Input.DropDown
        label='Six Options'
        items={[
          {
            label: 'First option label',
            value: 'First option value',
            support: 'First option support',
          },
          {
            label: 'Second option label',
            value: 'Second option value',
            support: 'Second option support',
          },
          {
            label: 'Third option label',
            value: 'Third option value',
            support: 'Third option support',
          },
          {
            label: 'Fourth option label',
            value: 'Fourth option value',
            support: 'Fourth option support',
          },
          {
            label: 'Fifth option label',
            value: 'Fifth option value',
            support: 'Fifth option support',
          },
          {
            label: 'Sixth option label',
            value: 'Sixth option value',
            support: 'Sixth option support',
          },
        ]}
        placeholder='Select an option'
        helper='You can select an option'
      />
      <Gap size='f24' height />
      <Input.DropDown
        label='Disabled options'
        items={dropDownItems.map((number) => {
          const item = `Dropdown Item ${number}`;
          const disabled = number % 2 === 0;

          return {
            label: item,
            value: item,
            disabled,
            support: disabled ? 'Disabled' : 'Enabled',
          };
        })}
        iconName='account-stroke'
        placeholder='Selection from list'
      />
      <Gap size='f24' height />
      <Input.DropDown
        label='Disabled dropdown'
        items={dropDownItems.map((number) => {
          const item = `Dropdown Item ${number}`;

          return { label: item, value: item };
        })}
        iconName='account-stroke'
        placeholder='Selection from list'
        disabled
        helper='Helper text'
      />

      <Gap size='f96' height />
      <Text.TitleBase>Text Area</Text.TitleBase>
      <Gap size='f48' height />

      <Input.TextArea
        label='Field label'
        helper='Helper text'
        placeholder='Hint text'
      />

      <Gap size='f24' height />

      <Input.TextArea
        label='Field label'
        helper='Helper text'
        placeholder='Hint text'
        error
      />

      <Gap size='f24' height />

      <Input.TextArea
        label='Field label'
        helper='Helper text'
        placeholder='Hint text'
        success
      />

      <Gap size='f24' height />

      <Input.TextArea
        label='Field label'
        helper='Helper text'
        placeholder='Hint text'
        disabled
      />

      <Gap size='f96' height />
      <Text.TitleBase>Pin Input</Text.TitleBase>
      <Gap size='f48' height />

      <Text.BodyBase>Regular - Default</Text.BodyBase>
      <Gap size='f16' height />

      <Input.PinInput value={pin} setValue={setPin} />

      <Gap size='f24' height />
      <Text.BodyBase>Regular - Error</Text.BodyBase>
      <Gap size='f16' height />

      <Input.PinInput
        value={pin}
        setValue={setPin}
        onChange={() => setError(true)}
        error={error}
        onCloseTooltip={() => setError(false)}
      />

      <Gap size='f28' height />
      <Text.BodyBase>Small - Default</Text.BodyBase>
      <Gap size='f16' height />

      <Input.PinInput size='small' value={pin} setValue={setPin} />

      <Gap size='f24' height />
      <Text.BodyBase>Small - Error</Text.BodyBase>
      <Gap size='f16' height />

      <Input.PinInput
        size='small'
        value={pin}
        setValue={setPin}
        onChange={() => setError(true)}
        error={error}
        onCloseTooltip={() => setError(false)}
      />

      <Gap size='f96' height />
      <DividerLine />
      <Gap size='f48' height />

      <Gap size='f48' height />

      <Text.TitleBase>File Upload</Text.TitleBase>

      <Gap size='f48' height />
      <Text.Body weight='bold'>
        * Single and Multiple Document Upload (Input.DocumentUpload) can be
        found in independent demo "FileUpload" screen.
      </Text.Body>
      <Gap size='f96' height />

      <Container>
        <Input.FileUpload label='Basic' />

        <Gap size='f24' height />

        <Input.FileUpload
          hasFile
          fileName='uploading.jpg'
          uploading
          progress={50}
        />

        <Gap size='f24' height />

        <Input.FileUpload hasFile fileName='uploaded.jpg' />

        <Gap size='f24' height />

        <Input.FileUpload hasFile fileName='uploaded.jpg' error />

        <Gap size='f24' height />

        <Input.FileUpload
          hasFile
          fileName='uploaded.jpg'
          label='Multiple'
          multiple
        />

        <Gap size='f24' height />

        <Input.FileUpload label='Disabled' disabled />
      </Container>

      <Gap size='f96' height />
      <DividerLine />
      <Gap size='f48' height />

      <Container>
        <Text.TitleBase>V1 Input</Text.TitleBase>
        <Gap size='f48' height />
        <Text.BodyBase>Radio Button</Text.BodyBase>
        <Gap size='f16' height />
        <Row>
          <Input.RadioButton label='normal' />
          <Input.RadioButton label='checked' checked />
          <Input.RadioButton label='disabled' disabled />
        </Row>
        <Gap size='f16' height />
        <Text.BodyBase>Check Box</Text.BodyBase>
        <Gap size='f16' height />
        <Row>
          <Input.CheckBox label='normal' />
          <Input.CheckBox label='checked' checked />
          <Input.CheckBox label='disabled' disabled />
        </Row>
      </Container>
    </Container>
  );
}
